import { IUser } from '@api/models/user.model';
import http from 'httpConfig/http';

export const getAllUsers = async () => {
    const { data } = await http.get(`${import.meta.env.VITE_APP_API_URL}/user`);

    const users = data.data;

    return users as IUser[];
};

export const getUsersById = async (userId: string) => {
    const { data } = await http.get(`${import.meta.env.VITE_APP_API_URL}/user/${userId}`);
    const user = data.user;
    return user as IUser;
};

export const addUser = async (payload: IUser) => {
    const { data } = await http.post(`${import.meta.env.VITE_APP_API_URL}/user`, payload);
    return { data };
};
export const updateUser = async (userId: string, payload: Partial<IUser>) => {
    const { data } = await http.put(`${import.meta.env.VITE_APP_API_URL}/user/${userId}`, payload);

    return data as IUser;
};

export const uploadImage = async (userId: string, payload: any) => {
    const { data } = await http.post(`${import.meta.env.VITE_APP_API_URL}/user/${userId}/profile-image`, payload);
    return data;
};

export const DeleteDP = async () => {
    await http.delete(`${import.meta.env.VITE_APP_API_URL}/profile/profile-image`);
};
