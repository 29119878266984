import { IListing, IUser } from '@api/models';
import { Button, Card, CircularProgress, HelpText, Input, Label } from '@hyperflake/react-ui-library';
import { ErrorMessage, Form, Formik } from 'formik';
import {
    CategoryOptions,
    DistrictsOptions,
    ListingStatusOptions,
    ListingTypeOptions,
} from 'modules/listing/helpers/listing.constants';
import useListingData from 'modules/listing/hooks/useListingData';
import useListingEditForm from 'modules/listing/hooks/useListingEditForm';
import { PageHeading, PageLoader, PageTemplate } from 'modules/shared/features/content';
import { FormikInput, FormikSelect, FormikSwitch, RequiredMark } from 'modules/shared/features/formik';
import FormikTextarea from 'modules/shared/features/formik/components/FormikTextarea/FormikTextarea';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ListingImagesForm from '../ListingImagesForm/ListingImagesForm';
import { formatNumeral, NumeralThousandGroupStyles } from 'cleave-zen';
import BackButton from 'modules/shared/components/BackButton/BackButton';

const ListingEditForm = () => {
    const { listingId } = useParams();

    const navigate = useNavigate();

    const { isLoading, listing, setImages } = useListingData(listingId);

    const listingEditForm = useListingEditForm(listing);

    const handleSubmit = async (values: Partial<IListing>) => {
        try {
            await listingEditForm.submit(values);

            toast.success('Listing updated successfully.');

            navigate('/listings');
        } catch (err: any) {
            console.log(err);

            toast.error(err?.data?.message || 'Some error occured, please try again.');
        }
    };

    if (isLoading) return <PageLoader />;

    const lister = listing.lister as IUser;

    return (
        <PageTemplate>
            <BackButton className="mb-2" />

            <PageHeading>Edit Listing</PageHeading>

            <div className="grid grid-cols-12 gap-6 mt-4">
                <div className="col-span-8">
                    <Card>
                        <Card.Header>
                            <h6>Images</h6>
                        </Card.Header>
                        <Card.Body>
                            <ListingImagesForm images={listing.images} setImages={setImages} />
                        </Card.Body>
                    </Card>

                    <Formik
                        initialValues={listingEditForm.initialValues}
                        enableReinitialize
                        validationSchema={listingEditForm.schema}
                        onSubmit={handleSubmit}
                    >
                        {({ values, setFieldValue }) => (
                            <Form>
                                <Card className="mt-4">
                                    <Card.Header>
                                        <h6>Basic Details</h6>
                                    </Card.Header>

                                    <Card.Body>
                                        <div className="grid grid-cols-2 gap-4">
                                            <div className="col-span-2">
                                                <Label>
                                                    Title <RequiredMark />
                                                </Label>

                                                <FormikInput type="text" name="title" />

                                                <HelpText variant={'error'}>
                                                    <ErrorMessage name="title" />
                                                </HelpText>
                                            </div>

                                            <div className="col-span-2">
                                                <Label>
                                                    Overview <RequiredMark />
                                                </Label>
                                                <FormikTextarea rows={2} name="overview" />

                                                <HelpText variant={'error'}>
                                                    <ErrorMessage name="overview" />
                                                </HelpText>
                                            </div>

                                            <div className="col-span-1">
                                                <Label>
                                                    Price <RequiredMark />
                                                </Label>

                                                <Input
                                                    value={formatNumeral(values.price.toString(), {
                                                        numeralThousandsGroupStyle: NumeralThousandGroupStyles.LAKH,
                                                    })}
                                                    onChange={(e) => {
                                                        setFieldValue('price', e.target.value);
                                                    }}
                                                />

                                                <HelpText variant={'error'}>
                                                    <ErrorMessage name="price" />
                                                </HelpText>
                                            </div>

                                            <div className="col-span-1">
                                                <Label>
                                                    Status <RequiredMark />
                                                </Label>

                                                <FormikSelect name="status" options={ListingStatusOptions} />

                                                <HelpText variant={'error'}>
                                                    <ErrorMessage name="status" />
                                                </HelpText>
                                            </div>

                                            <div className="col-span-1">
                                                <Label>
                                                    Category <RequiredMark />
                                                </Label>

                                                <FormikSelect name="category" options={CategoryOptions} />

                                                <HelpText variant={'error'}>
                                                    <ErrorMessage name="category" />
                                                </HelpText>
                                            </div>

                                            <div className="col-span-1">
                                                <Label>
                                                    Type <RequiredMark />
                                                </Label>

                                                <FormikSelect name="type" options={ListingTypeOptions} />

                                                <HelpText variant={'error'}>
                                                    <ErrorMessage name="type" />
                                                </HelpText>
                                            </div>

                                            <div className="mt-2">
                                                <div className="flex items-center gap-2">
                                                    <FormikSwitch color="success" name="isFeatured" />

                                                    <Label
                                                        htmlFor="isFeatured"
                                                        className="font-medium text-grayscale-900 mb-0"
                                                    >
                                                        Is Featured
                                                    </Label>
                                                </div>
                                                <div className="flex items-center gap-2 mt-6">
                                                    <FormikSwitch color="success" name="isVerified" />

                                                    <Label
                                                        htmlFor="isVerified"
                                                        className="font-medium text-grayscale-900 mb-0"
                                                    >
                                                        Is Verified
                                                    </Label>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>

                                {/* location */}
                                <Card className="mt-4 ">
                                    <Card.Header>
                                        <h6>Location</h6>
                                    </Card.Header>

                                    <Card.Body>
                                        <div className="grid grid-cols-2 gap-4">
                                            <div className="col-span-2">
                                                <Label>
                                                    Name <RequiredMark />
                                                </Label>

                                                <FormikInput type="text" name="location.name" />

                                                <HelpText variant={'error'}>
                                                    <ErrorMessage name="location.name" />
                                                </HelpText>
                                            </div>

                                            <div className="col-span-2">
                                                <Label>Landmark</Label>

                                                <FormikInput type="text" name="location.landmark" />

                                                <HelpText variant={'error'}>
                                                    <ErrorMessage name="location.landmark" />
                                                </HelpText>
                                            </div>

                                            <div className="col-span-2">
                                                <Label>
                                                    District <RequiredMark />
                                                </Label>

                                                <FormikSelect name="location.district" options={DistrictsOptions} />

                                                <HelpText variant={'error'}>
                                                    <ErrorMessage name="location.district" />
                                                </HelpText>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>

                                <Button type="submit" size={'lg'} className="mt-6 w-28">
                                    {listingEditForm.isSubmitting ? (
                                        <CircularProgress size={20} color={'inherit'} />
                                    ) : (
                                        'Submit'
                                    )}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </div>

                <div className="col-span-4">
                    <Card className="sticky top-20">
                        <Card.Header>
                            <h6>Lister Details</h6>
                        </Card.Header>
                        <Card.Body>
                            <div className="flex flex-col gap-4">
                                <div>
                                    <Label>Name</Label>
                                    <h6>{lister.name}</h6>
                                </div>
                                <div>
                                    <Label>Phone Number</Label>
                                    <h6>{lister.phoneNumber}</h6>
                                </div>
                                <div>
                                    <Label>Type</Label>
                                    <h6>{lister.type}</h6>
                                </div>

                                <div>
                                    <Label>Company Name</Label>
                                    <h6>{lister.company?.name || '-'}</h6>
                                </div>
                                <div>
                                    <Label>Company Address</Label>
                                    <h6>{lister.company?.address || '-'}</h6>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </PageTemplate>
    );
};

export default ListingEditForm;
