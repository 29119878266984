import { IListing, IListingImage } from '@api/models';
import { Button } from '@hyperflake/react-ui-library';
import { useMutation } from '@tanstack/react-query';
import http from 'httpConfig/http';
import { Img } from 'modules/shared/features/content';
import { DeleteIcon, UploadIcon } from 'modules/shared/icons';
import { ChangeEvent } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Zoom from 'react-medium-image-zoom';

interface ListingImagesFormProps {
    images: IListingImage[];
    setImages: (images: IListingImage[]) => void;
}
const ListingImagesForm = (props: ListingImagesFormProps) => {
    const { images, setImages } = props;

    const { listingId } = useParams();

    const uploadListingImagesMutation = useMutation(async (payload: FormData) => {
        const { data } = await http.post<IListing>(
            `${import.meta.env.VITE_APP_API_URL}/listing/${listingId}/images`,
            payload
        );

        return data.images;
    });

    const removeListingImageMutation = useMutation(async (imageId: string) => {
        await http.delete(`${import.meta.env.VITE_APP_API_URL}/listing/${listingId}/images/${imageId}`);
    });

    const handleListingImageRemove = async (imageId: string) => {
        if (images.length === 1) {
            toast.error('Listing should at least contain one image.');
            return;
        }
        try {
            const index = images.findIndex((val) => val._id === imageId);

            if (index === -1) {
                return;
            }

            await removeListingImageMutation.mutateAsync(imageId);

            setImages(images.filter((val) => val._id !== imageId));

            toast.success('Image deleted successfully.');
        } catch (err: any) {
            console.log(err);

            toast.error(err?.data?.message || 'Some error occured, please try again.');
        }
    };

    const handleImageUpload = async (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;

        if (files && files.length > 0) {
            const formData = new FormData();

            formData.append('files', files[0]);

            try {
                const images = await uploadListingImagesMutation.mutateAsync(formData);

                setImages(images);

                toast.success('Image Uploaded Successfully.');
            } catch (err: any) {
                console.log(err);

                toast.error(err?.data?.message || 'Some error occurred, please try again.');
            }
        }
    };

    return (
        <>
            <div className="flex flex-col h-full">
                <div className="grid grid-cols-3 gap-4 mb-4 empty:hidden">
                    {images.map((image) => (
                        <div key={image._id} className="col-span-1 relative rounded-md overflow-hidden">
                            <Zoom>
                                <Img
                                    src={image.url}
                                    className="w-full h-full aspect-square object-cover object-center"
                                />
                            </Zoom>

                            <button
                                className="absolute right-2 top-2 size-6 grid place-items-center bg-card-color rounded-md text-grayscale-500"
                                onClick={() => handleListingImageRemove(image._id)}
                            >
                                <DeleteIcon />
                            </button>
                        </div>
                    ))}
                </div>

                <div className="relative flex justify-center cursor-pointer bg-primary/10 py-10 rounded-lg">
                    <Button variant={'shaded'} size={'sm'} className="cursor-pointer ">
                        <UploadIcon className="cursor-pointer" /> Upload Image
                    </Button>

                    <input
                        type="file"
                        className="absolute z-20 top-0 left-0 bottom-0 right-0 w-full h-full opacity-0 cursor-pointer"
                        onChange={handleImageUpload}
                    />
                </div>
            </div>
        </>
    );
};

export default ListingImagesForm;
