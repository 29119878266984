import { CategoryEnum } from '@api/enums/category.enum';
import { ListingStatusEnum } from '@api/enums/listing-status.enum';
import { ListingTypeEnum } from '@api/enums/listing-type.enum';
import { IListingImage } from '@api/models/listing.model';
import { createListing } from '@api/services/listing.service';
import { useMutation } from '@tanstack/react-query';
import * as Yup from 'yup';

const useListingAddForm = () => {
    const addListingMutation = useMutation((payload: any) => createListing(payload));

    const schema = Yup.object({
        title: Yup.string().trim().required('Title is required'),
        overview: Yup.string().trim(),
        location: Yup.object({
            name: Yup.string().trim().required('Location name is required'),
            district: Yup.string().trim().required('District is required'),
            landmark: Yup.string().trim().nullable(),
            latitude: Yup.string().trim().nullable(),
            longitude: Yup.string().trim().nullable(),
        }).required('Location is required'),
        price: Yup.number().required('Price is required').min(0, 'Price must be a positive number'),
        // images: Yup.array().of(Yup.mixed().nullable()).required('At least one image is required'),
        category: Yup.string().required('Category is required'), // Assuming it's an enum
        type: Yup.string().required('Property type is required'), // Assuming it's an enum
        lister: Yup.mixed().required('Lister is required'), // This could be a string or an IUser object
        status: Yup.string().required('Listing status is required'), // Assuming it's an enum
        isVerified: Yup.boolean().required('Verification status is required'),
        isPublished: Yup.boolean().required('Publication status is required'),
        isFeatured: Yup.boolean().required('Featured status is required'),
        isEnabled: Yup.boolean().required('Enable status is required'),
        isDeleted: Yup.boolean().required('Deletion status is required'),
        coverImageUrl: Yup.string().trim().nullable(),
    });

    // type IListingPayload = Pick<IListing, 'category' | 'type' | 'title' | 'overview' | 'location' | 'price'> & {
    //     files?: File[];
    //     price: string;
    // };

    const initialValues = {
        title: '',
        overview: '',
        location: {
            name: '',
            district: '',
            landmark: '',
            latitude: '',
            longitude: '',
        },
        files: [] as any,
        price: 0,
        images: [] as IListingImage[],
        category: CategoryEnum.ALL, // Assuming CategoryEnum is an enum, it should be set to one of its values
        type: ListingTypeEnum.SALE, // Assuming ListingTypeEnum is an enum, it should be set to one of its values
        lister: '', // This could be a string or an IUser object depending on the context
        status: ListingStatusEnum.PENDING, // Assuming ListingStatus is an enum, it should be set to one of its values
        isVerified: false,
        isPublished: false,
        isFeatured: false,
        isEnabled: false,
        isDeleted: false,
        coverImageUrl: '',
    };
    const submit = async (values: any) => {
        const formData = new FormData();

        formData.append('title', values.title);
        formData.append('overview', values.overview);
        formData.append('category', values.category);
        formData.append('type', values.type);
        formData.append('status', values.status);
        formData.append('price', values.price);
        formData.append('lister', values.lister);
        // formData.append('Price', values.price);
        formData.append('location[name]', values.location.name);
        formData.append('location[district]', values.location.district);

        values.files.forEach((file: any) => {
            formData.append('files', file);
        });

        // if (values.files) {
        //     formData.append('files', values.files);
        // }
        // if (values.files) {
        //     formData.append('images', values.files);
        // }

        const data = await addListingMutation.mutateAsync(formData);

        return data;
    };
    return {
        initialValues,
        schema,
        submit,
    };
};

export default useListingAddForm;
